import Collapse from '@mui/material/Collapse';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC, useEffect, useMemo, useState } from 'react';

import { AssetManagementTable } from '../AssetManagementTable/AssetManagementTable';

import styles from './OwnerAccordion.module.scss';

import { ChevronClosed, ChevronOpened, CircleQuestion } from 'assets';
import { JitCheckbox } from 'components/JitCheckbox/JitCheckbox';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { JitText } from 'components/JitText/JitText';
import { JitTooltip } from 'components/JitTooltip/JitTooltip';
import { useTenantContext } from 'context/TenantContext';
import { i18n } from 'locale/i18n';
import colors from 'themes/colors.module.scss';
import { IAsset, IChangeEvent } from 'types/interfaces';
import { stopPropagation } from 'utils';

const DEFAULT_BACKLOG_BRANCH = 'Default';

interface Props {
  ownerName: string;
  assets: IAsset[];
  checkAsset: (assetId: string, checked: boolean) => void;
  checkMultipleAssetsByOwner: (assetId: string, checked: boolean) => void;
  accordionOwnerTypeTitle: string;
  accordionTableAssetColumnHeader: string;
}

export const OwnerAccordion: FC<Props> = ({
  ownerName,
  assets,
  checkAsset,
  checkMultipleAssetsByOwner,
  accordionOwnerTypeTitle,
  accordionTableAssetColumnHeader,
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  const { isControlledRepoActivation } = useFlags();
  const { initPreferences, preferences, isPreferencesLoading } = useTenantContext();

  const areAllAssetsOfOwnerSelected = assets.every((asset) => asset.is_covered);

  const toggleExpanded = () => setIsExpanded((currentExpanded) => !currentExpanded);

  const handleSelectAllAssetsOfOwner = (e: IChangeEvent, checked: boolean) => {
    checkMultipleAssetsByOwner(ownerName, checked);
  };

  const headerList = [
    {
      label: accordionTableAssetColumnHeader,
      key: 'asset_name',
    },
    {
      label: 'dialogs.assetsManagement.accordion.status',
      key: 'status',
    },
  ];

  useEffect(() => {
    if (!isPreferencesLoading && !preferences) {
      initPreferences();
    }
  }, [isPreferencesLoading, preferences, initPreferences]);

  const scannedBranch = useMemo(() => {
    if (isPreferencesLoading || !preferences) {
      return null;
    }
    if (preferences && preferences.backlog_branches && preferences.backlog_branches.backlog_branches && preferences.backlog_branches.backlog_branches.length > 0) {
      // only 1 backlog branch is supported
      return preferences.backlog_branches.backlog_branches[0];
    }
    return DEFAULT_BACKLOG_BRANCH;
  }, [preferences, isPreferencesLoading]);

  return (
    <div data-testid='owner'>
      <div
        className={styles.orgContainer}
        data-testid='owner-heading'
        onClick={toggleExpanded}
        role='button'
        tabIndex={0}
      >

        <JitCheckbox
          checked={areAllAssetsOfOwnerSelected}
          className={styles.ownerCheckBox}
          data-testid='owner-checkbox'
          disabled={isControlledRepoActivation}
          id={ownerName}
          onChange={handleSelectAllAssetsOfOwner}
          onClick={stopPropagation}
        />

        <JitText
          className={styles.ownerText}
          text={`${i18n.t(accordionOwnerTypeTitle)}: ${ownerName}`}
        />

        {!!scannedBranch && (
          <JitText
            className={styles.branchText}
            text={`Branch: ${scannedBranch}`}
          />
        )}

        {!!scannedBranch && (
          <JitTooltip
            childrenWrapperClassName={styles.toolTip}
            followCursor={false}
            offset={[0, 8]}
            placement='top'
            title={`${i18n.t('dialogs.assetsManagement.backlogBranchTooltip')}`}
          >
            <JitIcon
              color={colors.gray}
              data-testid='branch-tooltip'
              icon={CircleQuestion}
              size={16}
            />
          </JitTooltip>
        )}

        <div className={styles.iconContainer}>
          <JitIcon
            color={colors.gray}
            icon={isExpanded ? ChevronOpened : ChevronClosed}
            iconClassName={styles.icon}
          />
        </div>
      </div>

      <Collapse
        data-testid='owner-details'
        in={isExpanded}
        style={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <div className={styles.contentContainer}>
          <AssetManagementTable assets={assets} checkAsset={checkAsset} headers={headerList} />
        </div>
      </Collapse>
    </div>
  );
};
