import { difference } from 'lodash';
import { useState, useEffect, useMemo, useCallback, PropsWithChildren, FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { EditWorkflowContext } from './EditWorkflowContext';
import { buildInitialWorkflow } from './utils/buildInitialWorkflow';

import { mockWorkflow } from 'mocks/Workflows/mockWorkflow';
import { useWorkflowsService } from 'services/WorkflowsService/useWorkflowsService';
import { Queries } from 'types/enums/Queries';
import { IWorkflow, Step, DefinedStep } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowStepOption } from 'types/interfaces/Workflows/IWorkflowStepOptions';

const NEW_WORKFLOW_PATH_PARAM = 'new';

export const EditWorkflowProvider: FC<PropsWithChildren> = ({ children }) => {
  const [workflow, setWorkflow] = useState<IWorkflow>(mockWorkflow);
  const [selectedStepId, setSelectedStepId] = useState<string | undefined>();

  const { workflowId } = useParams<{ workflowId: string }>();
  const { getStepsOptions } = useWorkflowsService();
  const {
    data: stepsOptions,
    isLoading: isLoadingStepsOptions,
  } = useQuery([Queries.WorkflowsStepsOptions], getStepsOptions, {
    keepPreviousData: true,
  });

  useEffect(() => {
    if (workflowId === NEW_WORKFLOW_PATH_PARAM) {
      const newWorkflow = buildInitialWorkflow();
      setWorkflow(newWorkflow);
    } else {
      setWorkflow(mockWorkflow);
    }
  }, [workflowId]);

  const openStepDetails = useCallback((stepId: string) => {
    setSelectedStepId(stepId);
  }, []);

  const closeStepDetailsPanel = useCallback(() => {
    setSelectedStepId(undefined);
  }, []);

  const getStepNextOptions = useCallback(
    (step: Step): IWorkflowStepOption[] => {
      const isConfigured = !!step.type && !!step.stepType;
      if (!isConfigured || !stepsOptions) return [];

      const definedStep = step as DefinedStep;

      const stepTypeOptions = stepsOptions[`${definedStep.stepType}Options`];
      const stepOutput = stepTypeOptions.find((option) => option.type === definedStep.type)?.output;
      if (!stepOutput || !stepOutput?.length) return [];
      const nonTriggerStepOptions = [
        ...stepsOptions.conditionOptions,
        ...stepsOptions.actionOptions,
      ];
      const nextOptions = nonTriggerStepOptions.filter(
        (option) => difference(option.input, stepOutput).length === 0,
      );
      return nextOptions;
    },
    [stepsOptions],
  );

  const contextValue = useMemo(
    () => ({
      workflow,
      setWorkflow,
      openStepDetails,
      getStepNextOptions,
      stepsOptions,
      isLoadingStepsOptions,
      selectedStepId,
      setSelectedStepId,
      closeStepDetailsPanel,
    }),
    [
      workflow,
      openStepDetails,
      getStepNextOptions,
      stepsOptions,
      isLoadingStepsOptions,
      selectedStepId,
      closeStepDetailsPanel,
    ],
  );

  return (
    <EditWorkflowContext.Provider value={contextValue}>
      {children}
    </EditWorkflowContext.Provider>
  );
};
