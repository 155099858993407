import type { EdgeTypes, NodeTypes } from '@reactflow/core/dist/esm/types';
import { useEffect, useCallback, useState, FC, useRef } from 'react';
import ReactFlow, { Background, useNodesState, useEdgesState, BackgroundVariant, ReactFlowInstance, Node, Edge } from 'reactflow';

import styles from './JitGraph.module.scss';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import colors from 'themes/colors.module.scss';

interface Props {
  nodes: Node[];
  edges: Edge[];
  isLoading?: boolean;
  getLayoutedElements?: (nodes: Node[], edges: Edge[]) => Promise<{ nodes: Node[]; edges: Edge[] }>;
  edgeTypes?: EdgeTypes;
  nodeTypes?: NodeTypes;
  backgroundColor?: string;
}

const GRAPH_LOADING_TIMEOUT_MS = 2000;

export const JitGraph: FC<Props> = ({
  nodes: initialNodes,
  edges: initialEdges,
  isLoading: isParentLoading,
  getLayoutedElements,
  edgeTypes,
  nodeTypes,
  backgroundColor = colors.cards,
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState<Node[]>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [isAdjustingLayout, setIsAdjustingLayout] = useState(true);
  const reactFlowInstance = useRef<ReactFlowInstance | null>(null);

  const layoutNodesAndEdges = useCallback(async () => {
    if (!getLayoutedElements) {
      setIsAdjustingLayout(false);
      setNodes(initialNodes);
      setEdges(initialEdges);
      return;
    }

    const {
      nodes: layoutedNodes,
      edges: layoutedEdges,
    } = await getLayoutedElements(initialNodes, initialEdges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    // In 'Happy flow', we'll set this state to false as part of `onNodesChange`.
    // but this handles the case when the graph is empty and the event is not triggered
    setTimeout(() => setIsAdjustingLayout(false), GRAPH_LOADING_TIMEOUT_MS);
  }, [getLayoutedElements, initialNodes, initialEdges, setNodes, setEdges]);

  useEffect(() => {
    layoutNodesAndEdges();
  }, [isParentLoading, layoutNodesAndEdges]);

  const isLoading = isAdjustingLayout || isParentLoading;
  const fitView = useCallback(() => {
    reactFlowInstance?.current?.fitView({
      padding: 0.3,
    });
  }, []);

  useEffect(() => {
    if (isLoading) return;
    // Reload view after all nodes and edges are loaded
    fitView();
  }, [isLoading, nodes, edges, fitView]);

  return (
    <div className={styles.wrapper} data-testid='JitGraph' style={{ backgroundColor }}>
      {isLoading && (
        <div className={styles.spinnerWrapper}>
          <CircularLoadingSpinner size='large' />
        </div>
      )}

      <ReactFlow
        edges={isParentLoading ? [] : edges}
        edgeTypes={edgeTypes}
        fitView
        minZoom={0.01}
        nodes={isParentLoading ? [] : nodes}
        nodeTypes={nodeTypes}
        onEdgesChange={onEdgesChange}
        onInit={(instance) => {
          reactFlowInstance.current = instance;
        }}
        onNodesChange={(changes) => {
          onNodesChange(changes);
          setIsAdjustingLayout(false);
        }}
        proOptions={{ hideAttribution: true }}
      >
        <Background color={colors.cardsDivider} gap={16} variant={BackgroundVariant.Dots} />
      </ReactFlow>
    </div>
  );
};
