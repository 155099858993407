import ELK, { ElkNode } from 'elkjs';
import { Edge } from 'reactflow';

import { WorkflowNode } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/types';

export const NODE_HEIGHT = 100;
export const NODE_WIDTH = 280;

export const ADD_STEP_NODE_WIDTH = 30;
export const ADD_STEP_NODE_HEIGHT = 30;
export const getLayoutedElements = async (
  nodes: WorkflowNode[],
  edges: Edge[],
): Promise<{
  nodes: WorkflowNode[];
  edges: Edge[];
}> => {
  const elk = new ELK();

  const layout = await elk.layout({
    id: 'root',
    layoutOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      'elk.spacing.nodeNode': (NODE_WIDTH * 0.5).toString(),
      'elk.layered.spacing.nodeNodeBetweenLayers': (NODE_HEIGHT * 2).toString(),
      'elk.layered.nodePlacement.strategy': 'SIMPLE',
    },
    children: nodes.map((node) => ({
      ...node,
      width: node.type === 'addStepNode' ? ADD_STEP_NODE_WIDTH : NODE_WIDTH,
      height: node.type === 'addStepNode' ? ADD_STEP_NODE_HEIGHT : NODE_HEIGHT,
    }) as ElkNode),
    edges: edges.map((edge) => ({
      id: edge.id,
      sources: [edge.source],
      targets: [edge.target],
    })),
  });

  const layoutedNodes = layout.children!.map((node: ElkNode) => ({
    ...node,
    position: {
      x: node.x,
      y: node.y,
    },
  })) as WorkflowNode[];

  return {
    nodes: layoutedNodes,
    edges,
  };
};
