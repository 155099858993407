export enum StepType {
  Trigger = 'trigger',
  Condition = 'condition',
  Action = 'action',

}

export interface IStep {
  stepType: StepType | undefined;
  id: string;
  name: string;
  next: string[];
  type: string | undefined;
}

export interface ITriggerStep extends IStep {
  stepType: StepType.Trigger;
}

export interface IConditionStep extends IStep {
  stepType: StepType.Condition;
  config: Record<string, any>;  // eslint-disable-line
}

export interface IActionStep extends IStep {
  stepType: StepType.Action;
  config: Record<string, any>; // eslint-disable-line
}

export type Step = ITriggerStep | IConditionStep | IActionStep | IStep;
export type DefinedStep = ITriggerStep | IConditionStep | IActionStep;

export interface IWorkflow {
  id: string;
  name: string;
  description?: string;
  tenantId: string;
  createdAt: string;
  updatedAt: string;
  creatorUserId: string;
  creatorUserName: string;
  isEnabled: boolean;
  trigger: ITriggerStep;
  steps: DefinedStep[];
  version: number;
  isLastVersion: boolean;
  lastRunAt?: string;
  isDeleted: boolean;
  status?: string;
}
