/* eslint-disable max-len */

import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { WorkflowTrigger, WorkflowCondition, DefaultAction } from 'assets';
import { getIconByVendor } from 'components/AssetType/utils/getIconByVendor';
import { WorkflowNode, WorkflowEdge } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/types';
import colors from 'themes/colors.module.scss';
import { IWorkflow, IActionStep, Step, DefinedStep } from 'types/interfaces/Workflows/IWorkflow';
import { IWorkflowStepOption } from 'types/interfaces/Workflows/IWorkflowStepOptions';

const buildEdge = (source: string, target: string): WorkflowEdge => ({
  id: `${source}-${target}`,
  source,
  target,
  type: 'customEdge',
  style: {
    stroke: colors.edgeColor,
    strokeWidth: 2,
  },
});

const buildNode = (step: Step, icon: FC): WorkflowNode => ({
  id: step.id,
  draggable: false,
  data: {
    icon,
    step,
  },
  position: {
    x: 0,
    y: 0,
  },
  type: 'customNode',
});

const buildAddStepNode = (parentStep: DefinedStep): WorkflowNode => ({
  id: `${parentStep.id}-addStepNode-${uuidv4()}`,
  draggable: false,
  data: {
    parentStep,
  },
  position: {
    x: 0,
    y: 0,
  },
  type: 'addStepNode',
});

const addAddStepNodeIfHasNextOptions = (step: Step, getStepNextOptions: (step: Step) => IWorkflowStepOption[], nodes: WorkflowNode[], edges: WorkflowEdge[]): void => {
  const nextOptions = getStepNextOptions(step);
  if (nextOptions.length) {
    const definedStep = step as DefinedStep;
    const addStepNode = buildAddStepNode(definedStep);
    nodes.push(addStepNode);
    edges.push(buildEdge(definedStep.id, addStepNode.id));
  }
};

export const translateWorkflowToGraph = (
  workflow: IWorkflow,
  getStepNextOptions: (step: Step) => IWorkflowStepOption[],
): {
  nodes: WorkflowNode[];
  edges: WorkflowEdge[]
} => {
  const nodes: WorkflowNode[] = [];
  const edges: WorkflowEdge[] = [];

  nodes.push(buildNode(workflow.trigger, WorkflowTrigger));

  workflow?.trigger?.next?.forEach?.((nextStepId) => {
    edges.push(buildEdge(workflow.trigger.id, nextStepId));
  });

  // Add steps as nodes
  workflow.steps.forEach((step) => {
    let icon: FC;
    if (step.stepType === 'condition') {
      icon = WorkflowCondition;
    } else if (step.stepType === 'action') {
      icon = getIconByVendor((step as IActionStep).config?.vendor, undefined, true) || DefaultAction;
    } else {
      icon = DefaultAction;
    }

    nodes.push(buildNode(step, icon));

    // Create edges from trigger or other steps
    step.next.forEach((nextStepId) => {
      edges.push(buildEdge(step.id, nextStepId));
    });
  });

  // Add 'add step' node if step has next options
  addAddStepNodeIfHasNextOptions(workflow.trigger, getStepNextOptions, nodes, edges);
  workflow.steps.forEach((step) => {
    addAddStepNodeIfHasNextOptions(step, getStepNextOptions, nodes, edges);
  });

  return {
    nodes,
    edges,
  };
};
