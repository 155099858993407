import { FC } from 'react';

import styles from './AddRemovePopover.module.scss';
import { usePriorityFactorsState } from './usePriorityFactorsState';

import { CircularLoadingSpinner } from 'components/CircularLoadingSpinner/CircularLoadingSpinner';
import { JitActionButton } from 'components/JitActionButton/JitActionButton';
import { ConfirmDialog } from 'components/JitDialogs/ConfirmDialog';
import { JitDivider } from 'components/JitDivider/JitDivider';
import { PopoverEditMode } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/PopoverEditMode';
import { PopoverHeader } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/PopoverHeader';
import { PopoverViewMode } from 'pages/FindingsPage/components/FindingDetails/components/Priority/children/AddRemoveFactor/PopoverViewMode';
import { ActionButtonVariant } from 'types/interfaces';
import { IPriorityEntity, IPriorityFields } from 'types/interfaces/Graph/IPriorityFactor';

interface Props {
  priorityFields: IPriorityFields;
  entity: IPriorityEntity;
  onClose: () => void;
}

export const AddRemovePopover: FC<Props> = ({ priorityFields, entity, onClose }) => {
  const {
    isInEditMode,
    setIsInEditMode,
    priorityScoreToDisplay,
    enrichedOriginalFactors,
    toggleFactor,
    submitFactors,
    isApplyClicked,
    handleApplyClicked,
    hasFactorsChanged,
    isLoadingPriorityFactors,
    isSubmitting,
    updatedPriorityFactors,
    handleSubmitAssetFactors,
  } = usePriorityFactorsState(priorityFields, entity);

  if (isApplyClicked) {
    return (
      <ConfirmDialog
        actionButtons={[
          {
            label: 'pages.findings.findingDetails.priority.addRemoveFactors.assetConfirmation.onlyFindingBtn',
            isLoading: isSubmitting,
            variant: ActionButtonVariant.SECONDARY,
            handleClick: submitFactors,
          },
          {
            label: 'pages.findings.findingDetails.priority.addRemoveFactors.assetConfirmation.resourceAndFindingsBtn',
            isLoading: isSubmitting,
            variant: ActionButtonVariant.PRIMARY,
            handleClick: handleSubmitAssetFactors,
          },
        ]}
        isOpen
        message='pages.findings.findingDetails.priority.addRemoveFactors.assetConfirmation.message'
        onClose={() => setIsInEditMode(false)}
        subMessage='pages.findings.findingDetails.priority.addRemoveFactors.assetConfirmation.subMessage'
        title='pages.findings.findingDetails.priority.addRemoveFactors.assetConfirmation.title'

      />
    );
  }

  let body;
  if (isLoadingPriorityFactors) {
    body = (
      <div className={styles.spinnerWrapper}>
        <CircularLoadingSpinner size='large' />
      </div>
    );
  } else if (isInEditMode) {
    body = (
      <>
        <PopoverEditMode scope={priorityFields.type} selectedFactors={updatedPriorityFactors} toggleFactor={toggleFactor} />

        <div className={styles.submitButtonWrapper} data-testid='ApplyButton'>
          <JitActionButton actionButton={{
            handleClick: handleApplyClicked,
            label: 'pages.findings.findingDetails.priority.addRemoveFactors.apply',
            variant: ActionButtonVariant.PRIMARY,
            disabled: isLoadingPriorityFactors || !hasFactorsChanged,
            isLoading: isSubmitting,
          }}
          />
        </div>
      </>
    );
  } else {
    body = <PopoverViewMode selectedFactors={enrichedOriginalFactors} setIsInEditMode={setIsInEditMode} />;
  }

  return (
    <div className={styles.wrapper} data-testid='AddRemovePopover'>
      <PopoverHeader
        isInEditMode={isInEditMode}
        onClose={onClose}
        priorityScore={priorityScoreToDisplay}
        setIsInEditMode={setIsInEditMode}
      />

      <JitDivider />

      <div className={styles.body}>
        {body}
      </div>
    </div>
  );
};
