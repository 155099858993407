
import { FC } from 'react';

import styles from './TriggerSection.module.scss';

import { JitButton } from 'components/JitButton/JitButton';
import { JitMenu } from 'components/JitMenu/JitMenu';
import { JitText } from 'components/JitText/JitText';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext';
import colors from 'themes/colors.module.scss';

export const TriggerSection: FC = () => {
  const {
    workflow,
    setWorkflow,
    stepsOptions,
    setSelectedStepId,
    selectedStepId,
  } = useEditWorkflowContext();

  const triggerOptions = (stepsOptions?.triggerOptions || []);

  const menuItems = triggerOptions.map((option) => ({
    itemKey: option.type,
    displayText: option.label,
    itemContent: <JitText text={option.label} />,
    isSelected: workflow.trigger?.type === option.type,
  }));

  const onSelectTriggerOptions = (itemKey: string) => {
    const triggerOption = triggerOptions.find((option) => option.type === itemKey);
    if (!triggerOption) return; // Shouldn't happen

    setWorkflow((prevWorkflow) => ({
      ...prevWorkflow,
      trigger: {
        ...(prevWorkflow.trigger || {}),
        type: triggerOption.type,
        name: triggerOption.label,
      },
    }));
    setSelectedStepId(selectedStepId);
  };

  const triggerTypeDisplayName = triggerOptions.find((option) => option.type === workflow.trigger?.type)?.label || 'pages.workflows.editWorkflowPage.stepDetails.trigger.addTrigger';

  return (
    <div className={styles.triggerSection}>
      <JitText bold muted size='s' text='pages.workflows.editWorkflowPage.stepDetails.trigger.ctaQuestion' />

      <JitMenu
        menuItems={menuItems}
        menuSelectedDesign='fillRow'
        onMenuItemClick={({ itemKey }) => onSelectTriggerOptions(itemKey as string)}
        paperProps={{ style: { width: 180 } }}
        shouldCloseOnItemClick
      >
        <JitButton noHover variant='outlined' width={150}>
          <JitText color={colors.iris} data-testid='TriggerStepType' text={triggerTypeDisplayName} />
        </JitButton>
      </JitMenu>
    </div>
  );
};
