import { StepDetails } from './components/StepDetails/StepDetails';
import { WorkflowGraph } from './components/WorkflowGraph/WorkflowGraph';
import { useEditWorkflowContext } from './EditWorkflowContext';

import { JitSlidingPanel } from 'components/JitSlidingPanel/JitSlidingPanel';

export const EditWorkflowPage = () => {
  const {
    selectedStepId,
    closeStepDetailsPanel,
  } = useEditWorkflowContext();

  return (
    <>
      <WorkflowGraph />

      <JitSlidingPanel
        closePanel={closeStepDetailsPanel}
        isOpen={!!selectedStepId}
        width='50%'
      >
        {selectedStepId && <StepDetails />}
      </JitSlidingPanel>
    </>
  );
};
