import { FC, useState } from 'react';
import { NodeProps, Handle, Position } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import styles from './AddStep.module.scss';

import { Plus } from 'assets';
import { JitIcon } from 'components/JitIcon/JitIcon';
import { WorkflowAddStepNodeData } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/types';
import { ADD_STEP_NODE_WIDTH, ADD_STEP_NODE_HEIGHT } from 'pages/WorkflowsPage/EditWorkflowPage/components/WorkflowGraph/visualUtils';
import { useEditWorkflowContext } from 'pages/WorkflowsPage/EditWorkflowPage/EditWorkflowContext';
import colors from 'themes/colors.module.scss';
import { IConditionStep, StepType } from 'types/interfaces/Workflows/IWorkflow';

export const AddStepNode: FC<NodeProps<WorkflowAddStepNodeData>> = ({
  id,
  data: { parentStep },
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    closeStepDetailsPanel,
    setWorkflow,
  } = useEditWorkflowContext();

  const onClick = () => {
    const newStep = {
      id: `new-step-${uuidv4()}`,
      stepType: StepType.Condition,
      name: 'Description',
      next: [],
      type: undefined,
      config: {},
    } as IConditionStep;

    if (parentStep.stepType === 'trigger') {
      setWorkflow((prevWorkflow) => ({
        ...prevWorkflow,
        trigger: {
          ...prevWorkflow.trigger,
          next: [...prevWorkflow.trigger.next, newStep.id],
        },
        steps: [...prevWorkflow.steps, newStep],
      }));
    } else {
      setWorkflow((prevWorkflow) => {
        const updatedSteps = prevWorkflow.steps.map((step) => {
          if (step.id === parentStep.id) {
            return {
              ...step,
              next: [...step.next, newStep.id],
            };
          }
          return step;
        });
        return ({
          ...prevWorkflow,
          steps: [...updatedSteps, newStep],
        });
      });
    }
    closeStepDetailsPanel();
  };

  return (
    <div
      className={`${styles.node} ${isHovered ? styles.hoveredNode : ''}`}
      data-testid={`customGraphAddStepNode-${id}`}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      role='button'
      style={{
        width: ADD_STEP_NODE_WIDTH,
        height: ADD_STEP_NODE_HEIGHT,
      }}
      tabIndex={0}
    >
      <JitIcon color={colors.appBgColor} icon={Plus} size={14} />

      <Handle position={Position.Top} style={{ visibility: 'hidden' }} type='target' />

      <Handle position={Position.Bottom} style={{ visibility: 'hidden' }} type='source' />
    </div>
  );
};

